export const API_MEDIA = '/api/media/avatar/';

export const API_OPTIONS = '/api/work-record/options';
export const API_LOGIN_UNIVERSAL = '/api/auth/login/universal';
export const API_LOGIN_REFRESH = '/api/auth/login/refresh';
export const API_LOGIN_SAML = '/api/auth/login/saml/init';
export const API_LOGOUT = '/api/auth/logout';
export const API_USERS_SEARCH = '/api/users/search';
export const API_VERSION = '/api/version';

export const API_REVIEW_CYCLE = '/api/review-cycle';
export const API_REVIEW_CYCLE_FULL_LIST = '/api/review-cycle/full-list';
export const API_REVIEW_CYCLES_OPTIONS =
  '/api/review-cycle/options/review-cycles';
export const API_REVIEW_CYCLE_TEMPLATES_SHORT_LIST =
  '/api/review-cycle/{id}/template-short-list';
export const API_COUNTRY_STATS = '/api/work-record/options/countries/stats';

export const API_SCORECARD_TEMPLATE = '/api/scorecard-template';
export const API_SCORECARD_TEMPLATE_PUBLISH =
  '/api/scorecard-template/{id}/publish';
export const API_SCORECARD_TEMPLATE_DUPLICATE =
  '/api/scorecard-template/{id}/duplicate';
export const API_SCORECARD_TEMPLATE_AVAIL_RECORDS =
  '/api/scorecard-template/{id}/available-work-records';
export const API_SCORECARD_TEMPLATE_RECOM_RECORDS =
  '/api/scorecard-template/{id}/recommended-work-records';
export const API_COMMON_GOAL_TEMPLATES = '/api/scorecard-template/common-goals';
export const API_COMMON_GOAL_CATEGORY =
  '/api/scorecard-template/common-goals/category';
export const API_COMMON_GOALS_BY_CATEGORY_ID =
  '/api/scorecard-template/common-goals/category/{id}/goals';
export const API_COMMON_GOAL_CATEGORY_BY_ID =
  '/api/scorecard-template/common-goals/category/{id}';
export const API_SCORECARD_TEMPLATE_RECIPIENTS =
  '/api/scorecard-template/{id}/recipients';

export const API_SCORECARD = '/api/scorecard';
export const API_SCORECARD_DELETED = '/api/scorecard/deleted';
export const API_SCORECARD_GOALS = '/api/scorecard/goals';
export const API_SCORECARD_GOALS_ADD = '/api/scorecard/{id}/goals';
export const API_SCORECARD_ASSESSMENT_GROUPS_OPTIONS =
  '/api/scorecard/attribs/options/assessment-scale-group';
export const API_SCORECARD_ASSESSMENT_SCALES_OPTIONS =
  '/api/scorecard/attribs/options/assessment-scale/{id}';
export const API_SCORECARD_PARAM_TYPE_OPTIONS =
  '/api/scorecard/attribs/options/param-types';
export const API_SCORECARD_MULTIPLIERS_OPTIONS =
  '/api/scorecard/attribs/options/extra-multiplier';
export const API_ALL_CAREER_MENTORS_OPTIONS =
  '/api/work-record/org/career-mentor/all';
export const API_SUBORDINATE_CAREER_MENTORS_OPTIONS =
  '/api/work-record/org/career-mentor/subordinated';
export const API_SCORECARD_OWN = '/api/scorecard/own';
export const API_SCORECARD_APPLY = '/api/scorecard/{id}/apply';
export const API_SCORECARD_DELETE = '/api/scorecard/{id}';
export const API_SCORECARD_SEARCH = '/api/scorecard/search';

export const API_SCORECARD_FEEDBACK_REQUEST_RESEND_NOTIFICATION =
  '/api/scorecard/feedback/request/{id}/resend-notification';

export const API_SCORECARD_FULL_INFO = '/api/scorecard/{id}/full';

export const API_SCORECARD_EXPORT = '/api/scorecard/{id}/export';

export const API_SCORECARD_FEEDBACK_MODERATE =
  '/api/scorecard/{id}/feedback/moderate';

export const API_SCORECARD_REVIEWERS = '/api/scorecard/{id}/reviewers';
export const API_SCORECARD_RECCOMENDED_REVIEWERS =
  '/api/scorecard/{id}/reviewers/recommended';

export const API_FEEDBACK_REQUEST_OWN = '/api/scorecard/feedback/request/own';
export const API_FEEDBACK_REQUEST_OWN_HISTORY =
  '/api/scorecard/feedback/request/own/history';
export const API_SCORECARD_FEEDBACK_UPDATE = '/api/scorecard/feedback/{id}';
export const API_SCORECARD_FEEDBACK_RESET =
  '/api/scorecard/feedback/{id}/reset';
export const API_SCORECARD_FEEDBACK_CANCEL = `/api/scorecard/feedback/request/{id}/goal/{goalId}/decline`;

export const API_SCORECARD_AUDIT_LOGS = '/api/scorecard/{id}/audit-logs';

export const API_SUPPORT_REQUEST = '/api/support/request';

export const API_CUSAT_OPTIONS_PROJECTS_SHORT =
  '/api/cusat/options/projects/short';
export const API_CUSAT_SCORECARD_ALLOCATED_PROJECTS =
  '/api/cusat/allocated-projects/scorecard/bulk';

export const API_USER_SUMMARY_OWN = '/api/user/summary/own';

export const API_WEBSOCKET_PATH = '/api/sock';
export const WEBSOCKET_NAMESPACE = '/notifications';

export const API_CAREER_ROADMAP_SCHEMA = '/api/career/roadmap/schema';

export const API_USER_MANAGEMENT_SEARCH = '/api/users/management/search';
export const API_USER_MANAGEMENT_ASSIGN_ROLES =
  '/api/users/management/{id}/roles';
export const API_USER_MANAGEMENT_AUDIT_LOGS =
  '/api/users/management/audit-logs';

export const API_USER_MANAGEMENT_REGISTRATION = '/api/users/register';

export const API_PASSWORD_RESET = '/api/users/pwd-reset';

export const API_ROLES_LIST = '/api/roles';
export const API_USER_PERMISSIONS = '/api/permissions/acl';
export const API_CAREER_ROADMAP = '/api/career/roadmap';
export const API_MAILING_SETTINGS = '/api/test/mailing-settings';

export const OKR_REPORTING_PERIOD_OPTIONS = '/api/okr/reporting-period/options';
export const OKR_OWN_OBJECTIVES_LIST =
  '/api/okr/reporting-period/{periodId}/objective/own';
export const OKR_OBJECTIVE_BY_ID =
  '/api/okr/reporting-period/{periodId}/objective/{id}';
export const OKR_OBJECTIVE_CREATE =
  '/api/okr/reporting-period/{periodId}/objective';
export const OKR_OBJECTIVE_CREATE_FOR_USER =
  '/api/okr/reporting-period/{periodId}/objective/user/{userId}';
export const OKR_MY_TEAM_USERS_DIRECT =
  '/api/okr/reporting-period/{periodId}/cm-team/direct';
export const OKR_MY_TEAM_USERS_FULL =
  '/api/okr/reporting-period/{periodId}/cm-team/full';
export const OKR_TEAM_OVERVIEW_USERS =
  '/api/okr/reporting-period/{periodId}/team/global';
export const OKR_OBJECTIVES_BY_USER_ID =
  '/api/okr/reporting-period/{periodId}/cm-team/user/{userId}';
export const OKR_USERS_SEARCH = '/api/okr/users/search';
export const OKR_PRECISE_ATTAINMENTS =
  '/api/okr/reporting-period/{periodId}/objective/{id}/precise-attainments';
export const OKR_PENDING_KEY_RESULTS =
  '/api/okr/reporting-period/{periodId}/binding/request/own';
export const OKR_PENDING_KEY_RESULTS_FOR_USER =
  '/api/okr/reporting-period/{periodId}/binding/request/user/{userId}';
export const OKR_PENDING_KEY_RESULT_DECLINE =
  '/api/okr/reporting-period/{periodId}/binding/request/{id}/decline';
export const OKR_PENDING_KEY_RESULT_ACCEPT =
  '/api/okr/reporting-period/{periodId}/binding/request/{id}/accept';
export const OKR_OBJECTIVE_CREATE_COMMENT_THREAD =
  '/api/okr/reporting-period/{periodId}/objective/{objectiveId}/comment-thread';
export const OKR_KEY_RESULT_CREATE_COMMENT_THREAD =
  '/api/okr/reporting-period/{periodId}/objective/{objectiveId}/key-result/{keyResultId}/comment-thread';
export const OKR_OBJECTIVE_AUDIT_LOGS =
  '/api/okr/reporting-period/{reportingPeriodId}/objective/{objectiveId}/audit-logs';
export const API_GET_THREAD_INFO = '/api/comment/thread/{threadId}';
export const API_COMMENT = '/api/comment/thread/{threadId}/comment';
export const API_COMMENT_BY_ID =
  '/api/comment/thread/{threadId}/comment/{commentId}';
export const API_THREAD_SUBSCRIPTION =
  '/api/comment/thread/{threadId}/subscription';

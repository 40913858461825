import { Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { lazy, useEffect, useState } from 'react';
import { LogoIcon } from 'components/Icons';
import { jwtDecode } from 'jwt-decode';
import {
  Background,
  Block,
  Container,
  LoginContainer,
  LogInForm,
} from './SignUp.styled';

const EmailForm = lazy(() => import('./EmailForm.component'));
const NewPasswordForm = lazy(() => import('./NewPasswordForm.component'));

export default function ResetPassword() {
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      try {
        const decoded: { email?: string } = jwtDecode(token);
        if (decoded.email) {
          setEmail(decoded.email);
        }
      } catch (error) {
        console.error('Failed to decode token', error);
      }
    }
  }, [setEmail]);

  const theme = useTheme();

  return (
    <Background>
      <Container>
        <LoginContainer>
          <LogoIcon
            logoColor={theme.palette.common.white}
            textColor={theme.palette.common.white}
            width={200}
          />
        </LoginContainer>
        <LogInForm>
          {email ? (
            <Typography variant="h5">
              <FormattedMessage
                id="pages.resetPassword.newPasswordEmail"
                defaultMessage="New password for {userEmail}"
                values={{
                  userEmail: email,
                }}
              />
            </Typography>
          ) : (
            <>
              <Typography variant="h5">
                <FormattedMessage
                  id="pages.resetPassword.title"
                  defaultMessage="Reset password"
                />
              </Typography>
              <Typography variant="body5">
                <FormattedMessage
                  id="pages.resetPassword.enterEmailText"
                  defaultMessage="Please enter your Email and we will send you a link to reset your password."
                />
              </Typography>
            </>
          )}
          {email ? <NewPasswordForm /> : <EmailForm />}
        </LogInForm>
        <Block />
      </Container>
    </Background>
  );
}

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  useScorecardData,
  useScorecardPermissions,
  useUserSearch,
} from 'hooks';
import { getUserPosition } from 'helpers';
import { commonTexts } from 'i18n';
import { User } from 'store/interfaces';
import UserListItem from 'components/UserListItem';
import DialogEmptyState from 'components/DialogEmptyState';
import { DialogTitle, DialogActions } from '../Dialog';
import {
  Dialog,
  DialogContent,
  SearchWrapper,
} from './UserSelectDialog.styled';
import { UserSelectDialogProps } from './interfaces';
import SearchBox from '../SearchBox/SearchBox.component';
import SuggestedUsers from './SuggestedUsers.component';
import { isValidEmail } from '../../helpers/validation/isValidEmail';
import ReviewersList from './ReviewersList.component';

import messages from './messages';
import NoOptions from './NoOptions/NoOptions.component';

export default function UserSelectDialog({
  title,
  searchBoxLabel,
  searchBoxPlaceholder,
  listLabel,
  submitButtonText,
  cancelButtonText,
  children,
  initialUsers = [],
  isSubmitting,
  isRequired = false,
  excludeIds,
  enableSelfAssessment,
  onRemove,
  onSubmit,
  onClose,
}: UserSelectDialogProps) {
  const [users, setUsers] = useState(initialUsers);
  const [searchText, setSearchText] = useState<string | null>('');
  const { search } = useUserSearch(false, true);
  const { workRecord } = useScorecardData();
  const { user: scorecardOwner } = workRecord;
  const { canEditReviewers } = useScorecardPermissions();

  const onSearch = (query: string) => search({ searchText: query, excludeIds });

  const filterUsers = useCallback(
    (userOptions: User[]) =>
      userOptions.filter(({ id }) => !users.find((user) => user.id === id)),
    [users],
  );

  const addUser = (value: unknown) => {
    const userToAdd = value as User;

    if (!userToAdd) return;

    if (!users.find((user) => user.id === userToAdd.id)) {
      setUsers([...users, userToAdd]);
    }
  };

  const addAllSuggestedUsers = (suggestedUsers: User[]) => {
    const usersToAdd = suggestedUsers.filter(
      (user) => !users.find(({ id }) => id === user.id),
    );
    setUsers([...users, ...usersToAdd]);
  };

  const removeUser = (userToRemove: User) => {
    setUsers(users.filter(({ id }) => id !== userToRemove.id));
    if (onRemove) {
      onRemove(userToRemove);
    }
  };

  const submit = () => {
    onSubmit({
      users: users.filter((user) => !user.isNewExternalUser),
      newExternalUserEmails: users
        .filter((user) => user.isNewExternalUser)
        .map((user) => user.email),
    });
  };

  const handleNewUserCreate = () => {
    addUser({ id: searchText, email: searchText, isNewExternalUser: true });
  };

  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent id="setReviewersModal">
        <SearchWrapper>
          <SearchBox<User>
            isRequired={isRequired}
            searchLabel={searchBoxLabel}
            searchPlaceholder={searchBoxPlaceholder}
            onItemSelect={addUser}
            filterOptions={filterUsers}
            getOptionLabel={(user) =>
              typeof user === 'string' ? user : user.fullName
            }
            renderOption={(props, user) => (
              <UserListItem
                key={`search-option-${user.email}`}
                {...props}
                recipient={{
                  user,
                  position: { id: 0, name: getUserPosition(user) },
                }}
              />
            )}
            noOptionsComponent={() => (
              <NoOptions
                externalEmail={searchText}
                onExternalUserInvite={handleNewUserCreate}
              />
            )}
            onSearch={onSearch}
            noOptionsDisplayRule={isValidEmail}
            onSearchChange={setSearchText}
          />
        </SearchWrapper>
        <Typography sx={{ marginBottom: '16px' }} variant="body4">
          {listLabel}
        </Typography>
        <ReviewersList
          canEditReviewers={canEditReviewers}
          enableSelfAssessment={!!enableSelfAssessment}
          scorecardOwner={scorecardOwner}
          users={users}
          workRecord={workRecord}
          onRemoveUser={removeUser}
        />
        {!users.length && !(canEditReviewers && enableSelfAssessment) && (
          <DialogEmptyState
            title={<FormattedMessage {...messages.addReviewers} />}
          />
        )}
        <SuggestedUsers
          selectedUsers={users}
          addUser={addUser}
          addAllSuggestedUsers={addAllSuggestedUsers}
        />
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
          {cancelButtonText || <FormattedMessage {...commonTexts.cancel} />}
        </Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          disabled={!users.length && users.length === initialUsers?.length}
          onClick={submit}
        >
          {submitButtonText || <FormattedMessage {...commonTexts.submit} />}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

import { Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { lazy } from 'react';
import { LogoIcon } from 'components/Icons';
import {
  Background,
  Block,
  Container,
  LoginContainer,
  LogInForm,
} from './SignUp.styled';

const RegistrationForm = lazy(() => import('./RegistrationForm.component'));

export default function SignIn() {
  const theme = useTheme();
  return (
    <Background>
      <Container>
        <LoginContainer>
          <LogoIcon
            logoColor={theme.palette.common.white}
            textColor={theme.palette.common.white}
            width={200}
          />
        </LoginContainer>
        <LogInForm>
          <Typography variant="h5">
            <FormattedMessage
              id="pages.signUp.header"
              defaultMessage="Registration"
            />
          </Typography>
          <RegistrationForm />
        </LogInForm>
        <Block />
      </Container>
    </Background>
  );
}

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ExternalUserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
      <path
        d="M11.9743 6.03084L16.9298 7.82771C17.169 7.91651 17.3753 8.07639 17.521 8.28588C17.6667 8.49536 17.7448 8.74442 17.7448 8.99959C17.7448 9.25476 17.6667 9.50381 17.521 9.7133C17.3753 9.92279 17.169 10.0827 16.9298 10.1715L11.9743 11.973L10.172 16.9293C10.0832 17.1685 9.92328 17.3748 9.71379 17.5205C9.5043 17.6662 9.25525 17.7443 9.00008 17.7443C8.74491 17.7443 8.49585 17.6662 8.28636 17.5205C8.07688 17.3748 7.917 17.1685 7.8282 16.9293L6.02586 11.9683L1.07039 10.1715C0.831168 10.0827 0.624852 9.92279 0.479158 9.7133C0.333464 9.50381 0.255371 9.25476 0.255371 8.99959C0.255371 8.74442 0.333464 8.49536 0.479158 8.28588C0.624852 8.07639 0.831168 7.91651 1.07039 7.82771L6.03133 6.02537L7.8282 1.0699C7.917 0.83068 8.07688 0.624364 8.28636 0.47867C8.49585 0.332975 8.74491 0.254883 9.00008 0.254883C9.25525 0.254883 9.5043 0.332975 9.71379 0.47867C9.92328 0.624364 10.0832 0.83068 10.172 1.0699L11.9743 6.03084Z"
        fill="#FFC802"
      />
    </SvgIcon>
  );
}
